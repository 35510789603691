import { IActivityItem } from './components/pages/Activity/interfaces'

export enum ActivityFeedVerb {
  ContestCreate = 'contest_season_create',
  ContestVoting = 'contest_season_voting',
  ContestSubmission = 'contest_season_submission',
  ContestParticipate = 'contest_participate',
  Post = 'post',
  StartedConversation = 'started_conversation',
  RepliedToConversation = 'replied_to_conversation',
  PostCaptionMention = 'mention_in_post',
  ConversationCaptionMention = 'mention_in_conversation',
  ConversationReplyMention = 'mention_in_conversation_reply',
}

export enum AllowedActivityFeedVerbs {
  ContestCreate = 'contest_season_create',
  ContestVoting = 'contest_season_voting',
  ContestSubmission = 'contest_season_submission',
  ContestParticipate = 'contest_participate',
  Post = 'post',
  StartedConversation = 'started_conversation',
  RepliedToConversation = 'replied_to_conversation',
}

export enum Badge {
  DefaultMilestoneBadge = '',
  BronzeXPMilestoneBadge = 'BADGE_BRONZE',
  SilverXPMilestoneBadge = 'BADGE_SILVER',
  GoldXPMilestoneBadge = 'BADGE_GOLD',
  DiamondXPMilestoneBadge = 'BADGE_DIAMOND',
}

export enum MessageStatus {
  sending = 'sending',
  received = 'received',
  failed = 'failed',
}

export enum FollowStatus {
  Loading = 'LOADING',
  Follow = 'FOLLOW',
  Followed = 'FOLLOWED',
  FollowBack = 'FOLLOW_BACK',
  Following = 'FOLLOWING',
  Friends = 'FRIENDS',
  Blocking = 'BLOCKING',
  Blocked = 'BLOCKED',
  Error = 'ERROR',
  NotFollowing = 'NOT_FOLLOWING',
  Unknown = 'UNKNOWN',
}

export enum ContestCategory {
  Crypto = 'CRYPTO',
  NFT = 'NFT',
  Dance = 'DANCE',
  Music = 'MUSIC',
  'Art&Design' = 'ART & DESIGN',
  'Style&Beauty' = 'STYLE & BEAUTY',
  'Sports&Fitness' = 'SPORTS & FITNESS',
  Lifestyle = 'LIFESTYLE',
  Comedy = 'COMEDY',
  Gaming = 'GAMING',
  'Food&Drinks' = 'FOOD & DRINKS',
  'Hacks&Productivity' = 'HACKS & PRODUCTIVITY',
  Finance = 'FINANCE',
  Others = 'OTHERS',
}

export enum ContestType {
  PSSV = 'PSSV',
}

export enum Verb {
  Greeting = 'greeting',
  Follows = 'follows',
  ManyFollows = 'many_follows',
  VotedOnWinner = 'voted_on_winner',
  WonContest = 'won_contest',
  ResultsAnnounced = 'results_announced',
  ContestantRewarded = 'contestant_rewarded',
  VoterRewarded = 'voter_rewarded',
  UserDailyVotesConsumedRewarded = 'daily_votes_consumed_rewarded',
  WinnerRewarded = 'winner_rewarded',
  AccountCreatedReward = 'account_created_reward',
  PhoneVerifiedReward = 'phone_number_verified_reward',
  EmailVerifiedReward = 'email_verified_reward',
  VotesRefilled = 'votes_updated',
  InviteAcceptedReferral = 'invite_accepted_referral',
  InviteAcceptedUser = 'invite_accepted_user',
  FollowerMilestoneReachedReward = 'follower_milestone_reached_reward',
  ReferringMilestoneReachedReward = 'referring_milestone_reached_reward',
  JoinedAppReward = 'joined_app_reward',
  Comment = 'COMMENT',
  Mention = 'mention',
  RepliedToConversation = 'replied_to_conversation',
  StartedConversation = 'started_conversation',
  PinnedConversationReply = 'pinned_conversation_reply',
  Like = 'like',
  ContestParticipate = 'contest_participate',
  OpenedAppReward = 'opened_app_reward',
  ReceivedLikesReward = 'received_likes_reward',
  GivenLikesReward = 'given_likes_reward',
  PostCaptionMention = 'mention_in_post',
  ConversationCaptionMention = 'mention_in_conversation',
  ConversationReplyMention = 'mention_in_conversation_reply',
  ReachedXPMilestone = 'reached_xp_milestone',
  Post = 'post',
}

export enum State {
  Prod = 'production',
  Stage = 'staging',
  Dev = 'development',
}

export enum AuthError {
  Captcha = 'GeeCaptchaTokenNotValid',
  UserNotFound = 'UserNotFoundException',
  SignUpBlockedDomain = 'SignedUpBlockedDomain',
  SignInBlockedDomain = 'SignedInBlockedDomain',
  InvalidEmailCharacters = 'Email contains invalid characters',
  SignUpNotAvailable = 'Email registration is not available',
  BlockedPhoneNumber = 'Blocked phone number',
  DisabledUser = 'User is disabled',
  TryLater = 'Try later',
}

export enum LocationsBeenClicked {
  UserProfile = 'UserProfile',
  HomeFeed = 'HomeFeed',
}

export enum PostType {
  Text = 'Text',
  Image = 'Image',
  TextImage = 'TextImage',
}

export enum ReactionType {
  Comment = 'COMMENT',
}

export enum EmojiType {
  Like = 'like',
}

export enum PostType {
  Post = 'post',
  Conversation = 'conversation',
}

export enum RouterDirection {
  None = 'none',
  Forward = 'forward',
  Back = 'back',
  Root = 'root',
}

export enum RouterAction {
  Replace = 'replace',
  Pop = 'pop',
  Push = 'push',
}

export enum CommentActionType {
  Delete = 'delete',
  Report = 'report',
}

export enum FeedResultType {
  Contest = 'contest',
}

export enum ErrorCodes {
  NotFound = 404,
}

export enum ReportButtonType {
  Post = 'post',
  Video = 'video',
  Messaging = 'messaging',
  Conversation = 'started_conversation',
  ConversationReply = 'replied_to_conversation',
}

export enum UserRelations {
  Following = 'following',
  Followers = 'followers',
}

export enum WalletTabs {
  rpk = 'rpk',
  xp = 'xp',
}

export enum DateFormat {
  LocalizedDate = 'P',
  LocalizedTime = 'p',
}

export enum UserRelationIndicatorType {
  verticalLine = 'verticalLine',
  curvedRight = 'curvedRight',
}

export enum StoreKey {
  activity = 'initActivity',
  user = 'initUserFeed',
}

export enum PromoPoolItemType {
  Creator = 'CREATOR',
  Banner = 'BANNER',
  Conversation = 'CONVERSATION',
  Post = 'POST',
  Contest = 'CONTEST',
  Contestant = 'CONTESTANT',
}

export enum PromoPoolName {
  Creators = 'CREATORS',
  Conversations = 'CONVERSATIONS',
  Banners = 'BANNERS',
  Contests = 'CONTESTS',
  TopCreators = 'TOP_CREATORS',
}

export enum AuthFlow {
  SignUp = 'signUp',
  LogIn = 'logIn',
}

export enum SignInMethods {
  Email = 'email',
  Google = 'google',
  Facebook = 'facebook',
  Phone = 'phone',
  Apple = 'apple',
}

export enum MediaSource {
  Library = 'library',
  Camera = 'camera',
}

// It is used to identify the area of the contest page to enter the comment component
export enum ContestSections {
  Details = 'details',
  Participant = 'participant',
  Creator = 'creator',
}

// It is used to identify the area of the feed page post or conversations
export enum FeedSections {
  Post = 'post',
  Conversation = 'conversations',
}

export enum Pages {
  Contest = 'contest',
  Feed = 'feed',
  Discover = 'discover',
  Activity = 'activity',
  Profile = 'profile',
  LogIn = 'auth/log-in-option',
  SignUp = 'auth/sign-up-option',
}

export enum Platform {
  Web = 'Web',
  IOS = 'iOS',
  Android = 'Android',
}

export enum DiscoverTabs {
  Users = 'users',
  Challenges = 'challenges',
}

export enum pageStates {
  Loading = 'loading',
  FirstLoaded = 'firstLoaded',
  NonFirstLoaded = 'nonFirstLoaded',
}

export enum QueryKeys {
  CategoryFeed = 'categoryFeed',
  TimelineFeed = 'timelineFeed',
  TrendingFeed = 'trendingFeed',
  UserFeed = 'userFeed',
  SubscriptionFeed = 'subscriptionFeed',
  ExclusiveFeed = 'exclusiveFeed',
  Notifications = 'notifications',
  Reaction = 'reaction',
  ActivityData = 'activityData',
  ActivityDetails = 'activityDetails',
  CommentSection = 'commentSection',
  ConversationId = 'conversationId',
  EstimateWithdraw = 'estimateWithdraw',
  ExecuteWithdraw = 'executeWithdraw',
  Wallet = 'wallet',
  Transactions = 'transactions',
  StakeRules = 'stakeRules',
  StakesEstimate = 'stakesEstimate',
  StakesCreate = 'stakesCreate',
  StakesRedeem = 'stakesRedeem',
  StakesReStake = 'stakesReStake',
  MinDepositValue = 'minDepositValue',
  DepositData = 'depositData',
  ConnectInstagram = 'connectInstagram',
  ConnectTwitter = 'connectTwitter',
  SocialsList = 'socialsList',
  CreatorTokenConfig = 'creatorTokenConfig',
  LaunchToken = 'launchToken',
  LaunchTokenSuccess = 'tokenLaunchSuccess',
  DisconnectInstagram = 'disconnectInstagram',
  DisconnectTwitter = 'disconnectTwitter',
  ChatPaywall = 'chatPaywall',
  Subscription = 'subscription',
  SubscribersList = 'subscribersList',
  SubscriptionsList = 'subscriptionsList',
  DeleteSubscription = 'deleteSubscription',
  ChatStatus = 'chatStatus',
  XpMultipliers = 'xpMultipliers',
  TopCreators = 'topCreators',
  IsNewUser = 'isNewUser',
  PublicPosts = 'publicPosts',
  ScrollRestorationForm = 'scrollRestorationForm',
  SocialBackUrl = 'SocialBackUrl',
  TokenDetails = 'tokenDetails',
  TokenActivities = 'tokenActivities',
}

export enum MediaType {
  Video = 'video',
  Image = 'image',
}

export enum TransactionsType {
  All = 'all',
  Stake = 'stake',
  StakeRedeem = 'stake-redeem',
}

export enum StakeStatus {
  Locked = 'LOCKED',
  Unlocked = 'UNLOCKED',
  Scheduled = 'SCHEDULED',
  Rewarded = 'REWARDED',
  Redeemed = 'REDEEMED',
  Restaked = 'RESTAKED',
}

export enum SubscriptionStatus {
  Active = 'ACTIVE',
  Canceled = 'CANCELED',
}

export enum NetworkStandard {
  ERC20 = 'ERC-20',
}

export enum ProfileHashTabs {
  SUBSCRIPTION = '#subscription',
  POSTS = '#posts',
  STOX = '#STOX',
}

export type OwnReaction =
  | IActivityItem['own_reactions']['like'][number]
  | Record<string, unknown>

export type CachedReaction = {
  reactionsCount: number
  ownReactions: OwnReaction[]
  isLoading: boolean
}
