import { FC, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { IonLabel, IonSpinner } from '@ionic/react'
import { Dialog } from '@capacitor/dialog'
import { FacebookLogin } from '@capacitor-community/facebook-login'
import { useMutation, useQuery } from '@tanstack/react-query'

import { errorBoundary } from '@/services/errorBoundary'
import { FacebookAuthError, ssoSignIn } from '@/services/sso-signin'
import { getUserInfo } from '@/services/user'
import { setWebPushNotificationsExternalUserId } from '@/services/webPushNotifications'
import { useAmplitudeIdentifyUser } from '@/lib/hooks/useAmplitudeIdentifyUser'
import { queryClient } from '@/lib/queryClient'
import { useNavigate } from '@/lib/routing'
import { getStreamSetUserInfo } from '@/lib/tracking/platform/getstream'
import { Routes } from '@/router/routes'
import { safelyJsonStringify } from '@/utils/utils'

import { SignInMethods } from '@/enums'

import {
  trackingSigninCompleted,
  trackingSigninMethodSelected,
  trackingSignupSubmitted,
} from './TrackingSSO'

const SignInWithFacebook: FC = () => {
  const navigate = useNavigate()
  const { t } = useTranslation('common')

  const { amplitudeIdentifyUser } = useAmplitudeIdentifyUser()

  const {
    refetch: getUserData,
    error,
    isError,
  } = useQuery({
    queryKey: ['user'],
    queryFn: getUserInfo,
    retry: 5,
    enabled: false,
  })

  const { mutate, isPending } = useMutation({
    mutationKey: ['signInWithFacebook'],
    mutationFn: signInWithFacebook,
    onMutate: () => trackingSigninMethodSelected(SignInMethods.Facebook),
    onSuccess: async (data) => {
      trackingSigninCompleted(SignInMethods.Facebook)
      const user = await getUserData()
      if (data?._tag === 'NewRegistration') {
        await trackingSignupSubmitted(SignInMethods.Facebook)
      }
      onSuccess(user?.data)
    },
    onError: (e) => {
      errorBoundary(e)
      if (e instanceof Error && FacebookAuthError[e?.message]) {
        Dialog.alert({
          message: t(`registration.sso.error.${FacebookAuthError[e?.message]}`),
        })
      }
      console.error(`Error signing in with Facebook`, {
        e: safelyJsonStringify(e),
      })
    },
  })

  const setupPushNotification = (userId: string) => {
    setWebPushNotificationsExternalUserId(userId)
  }

  async function signInWithFacebook() {
    const response = await FacebookLogin.login({
      permissions: ['email'],
    })
    const profile = await FacebookLogin.getProfile<
      Record<'email' | 'id', string>
    >({
      fields: ['email', 'id'],
    })

    const data: { refUsername?: string; redirectUrl?: string } =
      queryClient.getQueryData(['referral'])

    return ssoSignIn(
      'facebook',
      {
        username: profile.email ? profile.email.toLowerCase() : profile.id,
        authToken: response.accessToken.token,
      },
      {
        userId: profile.id,
        refUsername: data?.refUsername,
        email: profile.email?.toLowerCase(),
      }
    )
  }

  const onSuccess = useCallback(
    (user) => {
      amplitudeIdentifyUser(user)
      setupPushNotification(user.id)
      if (user.username) {
        navigate(Routes.FEED, { replace: true })
        getStreamSetUserInfo(user.id, user.username)
      } else {
        navigate(Routes.AUTH_CONSENT, {
          replace: true,
          state: { direction: 'none' },
        })
      }
    },
    [amplitudeIdentifyUser, navigate]
  )

  useEffect(() => {
    if (isError) errorBoundary(error)
  }, [error, isError])

  return (
    <>
      <IonLabel
        onClick={() => mutate()}
        className="w-full z-10 mt-4 cursor-pointer flex flex-row justify-center text-sm bg-[#1877F2] text-white font-medium py-2 px-4 rounded-full focus:outline-none"
      >
        {isPending ? (
          <IonSpinner color="light" />
        ) : (
          <>
            <img
              src="/f-logo.png"
              alt="Facebook logo"
              className="mr-[8px] h-[18px]"
            />
            <span>{t(`registration.signUpOptions.signUpWithFacebook`)}</span>
          </>
        )}
      </IonLabel>
    </>
  )
}

export default SignInWithFacebook
