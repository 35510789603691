import { useMutation } from '@tanstack/react-query'

import { post } from '@/services/awsAmplify'

export const updateRevShare = ({
  revenueSharePercentage,
}: {
  revenueSharePercentage: number
}) => {
  return post({
    path: `/rev-share-percentage`,
    params: {
      revenueSharePercentage,
    },
  }) as unknown as Promise<{ revenueShareInPercentage: number }>
}

export const useUpdateRevShare = () => {
  return useMutation({
    mutationFn: updateRevShare,
  })
}
