import { useCallback, useRef } from 'react'
import { VirtuosoHandle } from 'react-virtuoso'

export const useHandleVirtuosoScrollToTop = () => {
  const virtuosoRef = useRef<VirtuosoHandle>(null)
  const DEFAULT_BEHAVIOR = 'smooth'

  const scrollToTop = useCallback(
    (behavior: 'smooth' | 'auto' = DEFAULT_BEHAVIOR) => {
      // Note that the default parameters of ordinary functions are SyntheticBaseEvent
      // ref: https://legacy.reactjs.org/docs/handling-events.html

      // This is a workaround to use a smooth-scroll that also takes
      // a list header into consideration
      virtuosoRef.current?.scrollToIndex({
        index: 0,
        align: 'end',
        behavior,
        offset: -1000,
      })
    },
    []
  )

  return { virtuosoRef, scrollToTop }
}
