import { FC, ReactNode, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IonButton } from '@ionic/react'
import { Share } from '@capacitor/share'
import { useCopyToClipboard } from 'usehooks-ts'

import { Button } from '@components/atoms/Button'
import { Popover } from '@components/molecules/Popover'

import { generateDeepLink } from '@/services/share'
import { useLocation } from '@/lib/routing'
import { Tracking, TrackingEvent } from '@/lib/tracking'

interface IShareButtonProps {
  subject?: string
  message: string
  buttonLabel?: ReactNode | string
  className?: string
  params?: { [key: string]: string }
  url: string
  imageUrl: string
  feature: string
  isRounded?: boolean
  color?: string
  plain?: boolean
  onClickShare?: () => void
}

export const ShareButton: FC<IShareButtonProps> = ({
  subject = '',
  message = '',
  buttonLabel,
  className,
  params,
  url,
  imageUrl,
  feature,
  isRounded,
  color = 'primary',
  plain,
  onClickShare,
}) => {
  const { pathname = '' } = useLocation()
  const { t } = useTranslation('common')
  const [, copy] = useCopyToClipboard()
  const [isPopoverVisible, setIsPopoverVisible] = useState({
    isOpen: false,
    body: null,
  })

  const createDeepLink = async () => {
    try {
      const analytics = {
        feature,
      }
      const properties = {
        $ios_passive_deepview: false,
        $desktop_url: url,
        $deeplink_path: params?.refUsername
          ? `${url}?ref_username=${params?.refUsername}`
          : url,
        referring_link: url,
        $ios_deepview: 'republik_default',
        $android_deepview: 'republik_default',
        $og_image_url: imageUrl,
        $og_type: 'website',
        $og_title: subject,
        $og_description: message,
        $twitter_card: 'summary_large_image',
        $twitter_title: subject,
        $twitter_description: message,
        $twitter_image_url: imageUrl,
        $twitter_creator: url,
        $twitter_site: url,
        $canonical_url: url,
        $always_deeplink: true,
        $desktop_web_only: true,
        ...params,
      }
      Tracking.triggerEvent(TrackingEvent.ReferralLinkClicked, {
        ...properties,
        pathname,
      })
      const result = await generateDeepLink({
        analytics,
        properties,
      })

      return result
    } catch (e) {
      console.log("Can't generate deep link", e)
      return url
    }
  }

  const onShareClick = async (e) => {
    onClickShare?.()
    e.stopPropagation()
    const refLink = await createDeepLink()
    const body = message + ' ' + refLink
    const { value } = await Share.canShare()
    if (value) {
      return Share.share({
        title: subject,
        text: message,
        url: refLink,
      }).catch(() => '')
    }
    await copy(refLink)
    setIsPopoverVisible({ isOpen: true, body })
  }

  return (
    <>
      <Popover
        hasIcon={false}
        isOpen={isPopoverVisible.isOpen}
        onDismiss={(e) => {
          e.stopPropagation()
          setIsPopoverVisible({ isOpen: false, body: null })
        }}
        headerMessage={t('copiedToClipboard')}
      >
        <div className="w-full break-words">{isPopoverVisible.body}</div>
      </Popover>
      {plain ? (
        <Button
          className={`${className} plain-button hover:bg-transparent`}
          hasFullWidth={false}
          onClick={(e) => onShareClick(e)}
          isRounded={isRounded}
          color={color}
          expand="full"
        >
          {buttonLabel || t('share.buttonLabel')}
        </Button>
      ) : (
        <IonButton
          className={`${className}`}
          disabled={false}
          color={color}
          onClick={(e) => onShareClick(e)}
        >
          <i slot="icon-only">{buttonLabel || t('share.buttonLabel')}</i>
        </IonButton>
      )}
    </>
  )
}
