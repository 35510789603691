import { get, post } from '@/services/awsAmplify'
import { FeeResult } from '@/lib/hooks/wallet/useWallet/useWallet'
export interface IWallet {
  id: string
  balance: string
  stakedBalance: string
  revenueShareBalance: string
  accumulativeRevenueShareBalance: string
  totalBalance: string
  revenueShareInPercentage: number
}

export const getWallet = async (userId: UUID): Promise<IWallet> => {
  return get({
    path: `/wallets/${userId}`,
  })
}

export const getFeeCalculation = async (
  walletId: UUID,
  value: string
): Promise<FeeResult> =>
  (await post({
    path: `/wallets/${walletId}/transactions/calculate-fee`,
    params: {
      amount: value,
    },
  })) as unknown as Promise<FeeResult>
