import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'

import { appConfig } from '@/services/config'
import { UserInfo } from '@/services/user'
import { useNavigate } from '@/lib/routing'
import { Routes } from '@/router/routes'
import { openExternalLink } from '@/utils/openExternalLink'

import { QueryKeys } from '@/enums'

import { StoxPreview } from './StoxPreview'
import { StoxSectionTemplate } from './StoxSectionTemplate'

interface StoxSectionContentProps {
  isAuthUser: boolean
  userInfo: UserInfo
  creatorTokenId?: string
}
export const StoxSectionContent: FC<StoxSectionContentProps> = ({
  isAuthUser,
  userInfo,
  creatorTokenId,
}) => {
  const { t } = useTranslation('common')
  const navigate = useNavigate()

  const { creatorToken } = appConfig

  const { data: isLaunchTokenSuccess } = useQuery({
    queryKey: [QueryKeys.LaunchTokenSuccess],
  })

  const navigateMarket = () => {
    openExternalLink({
      url:
        creatorTokenId != null
          ? `${appConfig.marketAppOrigin}/@${userInfo.username}`
          : `${appConfig.marketAppOrigin}/?type=new`,
    })
  }

  const navigateSocialsConnect = () => {
    navigate(Routes.TOKEN_SOCIALS_CONNECT)
  }

  if (creatorTokenId) {
    return <StoxPreview userInfo={userInfo} isAuthUser={isAuthUser} />
  }

  if (isAuthUser && creatorTokenId == null && isLaunchTokenSuccess) {
    return (
      <StoxSectionTemplate
        title={t('profile.creatorToken.pendingStateTitle', {
          creatorToken,
        })}
        subTitle={t('profile.creatorToken.pendingStateSubtitle')}
      />
    )
  }

  if (isAuthUser) {
    return (
      <StoxSectionTemplate
        title={t('profile.creatorToken.setUpTokenTitle', { creatorToken })}
        subTitle={t('profile.creatorToken.setUpTokenSubTitle', {
          creatorToken,
        })}
        buttonText={t('profile.creatorToken.setUpTokenButton', {
          creatorToken,
        })}
        onClick={navigateSocialsConnect}
      />
    )
  }

  return (
    <StoxSectionTemplate
      title={t('profile.creatorToken.seeOthersPerformingTitle', {
        creatorToken,
      })}
      buttonText={t('profile.creatorToken.seeOthersPerformingButton', {
        creatorToken,
      })}
      onClick={navigateMarket}
      userName={userInfo.username}
    />
  )
}
