import { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useIonToast } from '@ionic/react'
import { registerSW } from 'virtual:pwa-register'

const intervalMS = 60 * 1000

export const useServiceWorkerUpdateContent = () => {
  const [presentToast] = useIonToast()
  const { t } = useTranslation('common')
  const updatePwaInterval = useRef<NodeJS.Timeout>()
  const registrationRef = useRef<ServiceWorkerRegistration | null>(null)

  useEffect(() => {
    registerSW({
      onRegisteredSW(swUrl, r) {
        if (r) {
          registrationRef.current = r

          updatePwaInterval.current = setInterval(async () => {
            if (!(!r.installing && navigator)) return

            if ('connection' in navigator && !navigator.onLine) return

            try {
              const resp = await fetch(swUrl, {
                cache: 'no-store',
                headers: {
                  cache: 'no-store',
                  'cache-control': 'no-cache',
                },
              })

              if (resp?.status === 200) await r.update()
            } catch (error) {} // eslint-disable-line no-empty
          }, intervalMS)
        }
      },
      onNeedRefresh() {
        presentToast({
          id: 'update-pwa',
          message: t('common.refresh.title'),
          position: 'top',
          buttons: [
            {
              text: t('common.refresh.action'),
              role: 'cancel',
              handler: () => {
                const r = registrationRef.current
                if (r?.waiting) {
                  r.waiting.postMessage({ type: 'SKIP_WAITING' })
                }
              },
            },
          ],
        })
      },
    })
  }, [presentToast, t])
}
