import {
  del as apiDel,
  get as apiGet,
  patch as apiPatch,
  post as apiPost,
  put as apiPut,
} from 'aws-amplify/api'
import { AxiosError, AxiosResponse } from 'axios'
import omit from 'lodash/omit'
import qs from 'qs'

import { newrelicNoticeError } from '@/lib/tracking'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface ApiError<T = any> extends AxiosError<T> {
  response?: AxiosResponse & {
    metadata?: T
  }
}

export const axiosError = (e): Promise<ApiError> => {
  const isString = typeof e === 'string'
  if (isString) {
    newrelicNoticeError({ error: e })
  }

  const status = isString ? 400 : e?.$metadata?.httpStatusCode
  const data = isString ? 'Something went wrong' : e?.message
  let metadata = null

  try {
    metadata = JSON.parse(e?.response?.body)
    delete metadata?.message
  } catch (e) {
    // no need to handle
  }

  const response = omit({ status, data, metadata }, [null])

  return Promise.reject({
    response,
  })
}

export async function del({
  path,
  endpoint = 'default',
  headers = {},
  queryParams = {},
}) {
  try {
    const restOperation = apiDel({
      apiName: endpoint,
      path,
      options: {
        headers,
        queryParams,
      },
    })

    const response = (await restOperation?.response) as unknown as {
      body: Response
    }
    const data = await response?.body?.text()

    try {
      return JSON.parse(data)
    } catch (error) {
      return data
    }
  } catch (e) {
    return axiosError(e)
  }
}

export async function put({
  path,
  endpoint = 'default',
  params = {},
  headers = {},
}) {
  try {
    const restOperation = apiPut({
      apiName: endpoint,
      path,
      options: {
        headers,
        body: params,
      },
    })

    const response = await restOperation?.response

    return await response?.body.json()
  } catch (e) {
    return axiosError(e)
  }
}

export async function patch({
  path,
  endpoint = 'default',
  params = {},
  headers = {},
}) {
  try {
    const restOperation = apiPatch({
      apiName: endpoint,
      path,
      options: {
        headers,
        body: params,
      },
    })

    const response = await restOperation?.response

    return await response?.body.json()
  } catch (e) {
    return axiosError(e)
  }
}

export async function post({
  path,
  endpoint = 'default',
  params = {},
  headers = {},
}) {
  try {
    const restOperation = apiPost({
      apiName: endpoint,
      path,
      options: {
        headers,
        body: params,
      },
    })

    const response = await restOperation?.response
    return await response?.body.json()
  } catch (e) {
    return axiosError(e)
  }
}

export async function get({
  path,
  endpoint = 'default',
  params = {},
  headers = {},
}) {
  let result

  try {
    const restOperation = apiGet({
      apiName: endpoint,
      path: `${path}${qs.stringify(params, { addQueryPrefix: true })}`,
      options: {
        headers,
      },
    })
    const response = await restOperation?.response
    result = await response?.body.json()
  } catch (e) {
    result = axiosError(e)
  }

  return result
}
