import {
  FC,
  ForwardedRef,
  forwardRef,
  MouseEventHandler,
  MutableRefObject,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { VirtuosoGridHandle } from 'react-virtuoso'
import { useIonToast } from '@ionic/react'
import { AxiosError } from 'axios'
import SwiperClass from 'swiper'
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react'

import { InfiniteScrollLoader } from '@/components/atoms/InfiniteScrollLoader'
import { VirtualGrid } from '@/components/organisms/VirtualGrid'
import { GridItem, GridItemSkeleton } from './components'
import { RenderPostsSlide } from './components/slides/RenderPostsSlide'
import { RenderSubscriptionSlide } from './components/slides/subscription/RenderSubscriptionSlide'

import { AccountInfo } from '@/services/account'
import { TopSupporter } from '@/services/top-supporters'
import { UserInfo } from '@/services/user'
import { IWallet } from '@/services/wallet/getWallet'
import { useNavigate } from '@/lib/routing'
import { newrelicNoticeError } from '@/lib/tracking'

import { IonSegmentCustomEvent, SegmentChangeEventDetail } from '@/interfaces'

import { useProfileSections } from '../../hooks/useProfileSections'
import { generatePlaceholders } from '../../utils'
import { SectionComponent, SectionsButtonsTab, UserProfileHeader } from '../'
import { StoxSection } from '../StoxSection'
import { Section } from './types'

type UserProfileSectionsProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  activities: any[]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  subscriptionActivities: any[]
  isFetchingNextPage: boolean
  atTopStateChange: (isAtTop: boolean) => void
  onEndReached: (e) => NodeJS.Timeout
  virtuosoRef: MutableRefObject<VirtuosoGridHandle>
  isAuthUser: boolean
  userId: string
  hasFeedError?: boolean
  isFeedFetching?: boolean
  userInfo: UserInfo
  accountInfoData: AccountInfo
  wallet: IWallet
  isSubscriptionFeedFetching: boolean
  isSubscriptionFeedLoading: boolean
  isFetchingNextSubscriptionPage: boolean
  subscriptionFeedError: unknown | AxiosError | null
  topSupporters: readonly TopSupporter[]
  subcriptionPrice?: number
  onSubscribe: MouseEventHandler<HTMLIonFabButtonElement | HTMLIonButtonElement>
  isSubscriptionFetchedAfterMount: boolean
  creatorTokenId?: string
}

export const UserProfileSections: FC<UserProfileSectionsProps> = ({
  activities,
  subscriptionActivities,
  isFetchingNextPage,
  virtuosoRef,
  atTopStateChange,
  onEndReached,
  isAuthUser,
  userId,
  hasFeedError,
  isFeedFetching,
  userInfo,
  accountInfoData,
  wallet,
  isSubscriptionFeedFetching,
  isSubscriptionFeedLoading,
  isFetchingNextSubscriptionPage,
  subscriptionFeedError,
  topSupporters,
  onSubscribe,
  isSubscriptionFetchedAfterMount,
  creatorTokenId,
}) => {
  const { t } = useTranslation('common')
  const navigate = useNavigate()
  const [presentToast] = useIonToast()
  const { sectionIndex, normalizedSections } = useProfileSections({
    subscriptionFeedError,
    isSubscriptionFeedLoading,
    isSubscriptionFeedFetching,
    username: userInfo?.username,
  })

  const swiperRef = useRef<SwiperRef>(null)
  const [isViewLoaded, setIsViewLoaded] = useState(false)

  const handleSectionClick = useCallback(
    (e: IonSegmentCustomEvent<SegmentChangeEventDetail>) => {
      const sectionIndex = Number(e.detail.value)

      swiperRef.current?.swiper?.slideTo(sectionIndex)
      virtuosoRef.current?.scrollTo({ top: 0, behavior: 'instant' })
    },
    [virtuosoRef]
  )

  const handleSlideChange = useCallback(
    async (swiper: SwiperClass) => {
      navigate(`#${normalizedSections[swiper.activeIndex]?.name}`, {
        replace: true,
      })
    },
    [navigate, normalizedSections]
  )

  const [extendedActivities, setExtendedActivities] = useState([])

  useEffect(() => {
    const sectionName = normalizedSections[sectionIndex]?.name

    setExtendedActivities(
      generatePlaceholders(
        sectionName === 'posts'
          ? activities
          : sectionName === 'subscription'
          ? subscriptionActivities
          : []
      )
    )
  }, [activities, subscriptionActivities, normalizedSections, sectionIndex])

  useEffect(() => {
    setIsViewLoaded(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const Footer = useCallback(
    () => <InfiniteScrollLoader hasNext={!!isFetchingNextPage} />,
    [isFetchingNextPage]
  )

  const renderGridItem = useCallback(
    (index) => {
      if (!extendedActivities[index].isPlaceholder) {
        return <GridItem activity={extendedActivities[index]} />
      }

      if (isFetchingNextPage) return <GridItemSkeleton />

      return null
    },
    [extendedActivities, isFetchingNextPage]
  )

  const handleAtTopStateChange = useCallback(
    (atTop: boolean) => {
      atTopStateChange(atTop)
    },
    [atTopStateChange]
  )

  const Header = useCallback(
    () => (
      <UserProfileHeader
        wallet={wallet}
        userId={userId}
        isAuthUser={isAuthUser}
        userInfo={userInfo}
        accountInfoData={accountInfoData}
        topSupporters={topSupporters}
        subscriptionFeedError={subscriptionFeedError}
        onSubscribe={onSubscribe}
        isSubscriptionFeedFetching={isSubscriptionFeedFetching}
        isSubscriptionFetchedAfterMount={isSubscriptionFetchedAfterMount}
      />
    ),
    [
      isAuthUser,
      userId,
      userInfo,
      accountInfoData,
      wallet,
      topSupporters,
      subscriptionFeedError,
      onSubscribe,
      isSubscriptionFeedFetching,
      isSubscriptionFetchedAfterMount,
    ]
  )

  const RenderSwiperSlide = useCallback(
    ({
      section,
      isSubscriptionFeedFetching,
      isFeedFetching,
      tabIndex,
      props,
      index,
      ref,
      isFetchingNextPage,
      isFetchingNextSubscriptionPage,
    }: {
      section: Section
      props: { children?: ReactNode }
      index: number
      ref: ForwardedRef<HTMLDivElement>
      isFeedFetching: boolean
      isSubscriptionFeedFetching: boolean
      tabIndex: number
      isFetchingNextPage: boolean
      isFetchingNextSubscriptionPage: boolean
    }) => {
      switch (section.name) {
        case 'posts':
          return (
            <RenderPostsSlide
              section={section}
              activities={activities}
              isAuthUser={isAuthUser}
              forwardedRef={ref}
              assignedIndex={index}
              sectionIndex={tabIndex}
              isFetching={isFeedFetching}
              isFetchingNextPage={isFetchingNextPage}
              {...props}
            />
          )
        case 'subscription':
          return (
            <RenderSubscriptionSlide
              section={section}
              activities={subscriptionActivities}
              isAuthUser={isAuthUser}
              forwardedRef={ref}
              assignedIndex={index}
              sectionIndex={tabIndex}
              isFetching={isSubscriptionFeedFetching}
              userInfo={userInfo}
              subscriptionFeedError={subscriptionFeedError}
              isFetchingNextPage={isFetchingNextSubscriptionPage}
              {...props}
            />
          )
        case 'STOX':
          return (
            <StoxSection
              section={section}
              isAuthUser={isAuthUser}
              userInfo={userInfo}
              creatorTokenId={creatorTokenId}
            />
          )
        default:
          return <SectionComponent section={section} />
      }
    },
    [
      activities,
      subscriptionActivities,
      isAuthUser,
      userInfo,
      subscriptionFeedError,
      creatorTokenId,
    ]
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const List = useCallback(
    // eslint-disable-next-line react/display-name
    forwardRef<HTMLDivElement>((props, ref) => {
      const [tabIndex, setTabIndex] = useState(sectionIndex)

      if (normalizedSections.length === 0) {
        newrelicNoticeError({
          error: 'Error Tabs: User profile sections page tabs is empty',
          customAttributes: {
            location: window.location.pathname,
            tabIndex,
            username: userInfo?.username,
            isAuthUser,
            normalizedSections,
          },
        })
      }

      return (
        <>
          <SectionsButtonsTab
            sections={normalizedSections}
            selectedSectionIndex={tabIndex}
            handleSectionClick={handleSectionClick}
          />
          <Swiper
            ref={swiperRef}
            slidesPerView={1}
            initialSlide={tabIndex}
            onSlideChange={(e) => {
              setTabIndex(e.activeIndex)
              if (tabIndex !== e.activeIndex && isViewLoaded) {
                setExtendedActivities([])
              }
            }}
            onSlideChangeTransitionEnd={(e) => {
              setTimeout(() => {
                if (isViewLoaded) {
                  handleSlideChange(e)
                }
              }, 10)
            }}
          >
            {normalizedSections.map((section, index) => (
              <SwiperSlide
                key={section.name}
                className="!flex !items-start min-h-[55vh]"
              >
                {RenderSwiperSlide({
                  section,
                  index,
                  tabIndex,
                  isFeedFetching,
                  isSubscriptionFeedFetching,
                  isFetchingNextSubscriptionPage,
                  isFetchingNextPage,
                  props,
                  ref,
                })}
              </SwiperSlide>
            ))}
          </Swiper>
        </>
      )
    }),
    [
      normalizedSections.length,
      isViewLoaded,
      hasFeedError,
      isFeedFetching && !isFetchingNextPage,
      isSubscriptionFeedFetching && !isFetchingNextSubscriptionPage,
      subscriptionFeedError,
      isSubscriptionFeedFetching,
      sectionIndex,
      RenderSwiperSlide,
    ]
  )

  List.displayName = 'List'

  useEffect(() => {
    if (activities.length && hasFeedError) {
      presentToast({
        message: t('profile.error.newFeed'),
        position: 'top',
        duration: 3000,
      })
    }
  }, [hasFeedError, isFeedFetching, activities, presentToast, t])

  return (
    <div className="w-[100vw] max-w-[40rem] mx-auto flex flex-col flex-1">
      <VirtualGrid
        virtuosoRef={virtuosoRef}
        className="flex flex-col flex-1 mx-auto max-w-[40rem] w-full activities-grid"
        data={extendedActivities}
        overscan={{ main: 200, reverse: 200 }}
        itemClassName="w-1/3 h-[44.44vw] max-h-[284px] flex flex-none overflow-hidden border-[1px] box-border border-cool-gray-900"
        atTopStateChange={handleAtTopStateChange}
        endReached={onEndReached}
        components={{
          Footer,
          Header,
          List,
        }}
        itemContent={renderGridItem}
      />
    </div>
  )
}
