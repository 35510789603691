import { precacheAndRoute } from 'workbox-precaching'
import { registerRoute } from 'workbox-routing'

declare let self: ServiceWorkerGlobalScope

const manifest = self.__WB_MANIFEST || []

precacheAndRoute(manifest)

self.addEventListener('message', (event) => {
  if (event.data && event.data.type === 'SKIP_WAITING') {
    self.skipWaiting()
  }
})

export const cacheName = 'pwa-media-cache'

async function shareTargetHandler({ event }) {
  const req = event.request as Request
  const formData = await req.formData()
  const sharedUrl = formData.get('description')

  if (sharedUrl) return Response.redirect(`/share?shared-url=${sharedUrl}`, 303)

  const media = formData.getAll('media')
  const cache = await caches.open(cacheName)

  const files = media.slice(0, 3).map((file) => ({
    file,
    size: file.size,
    type: file.type,
    name: `${Date.now()}-${Math.random().toString(36).slice(2)}`,
  }))
  for (const { file, size, type, name } of files) {
    await cache.put(
      name,
      new Response(file, {
        headers: {
          'content-length': size,
          'content-type': type,
        },
      })
    )
  }

  return Response.redirect(
    `/post?shared-media=${files.map(({ name }) => name).join(',')}`,
    303
  )
}

registerRoute('/share', shareTargetHandler, 'POST')
