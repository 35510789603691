import { FC, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Slider } from '@/components/atoms/Slider'

import { useShowErrorToast } from '@/lib/hooks/useShowErrorToast'
import { useUpdateRevShare } from '@/lib/hooks/useUpdateRevShare'

type PriceBoosterSliderProps = {
  revenueShareInPercentage?: number
  onPriceBoosterUpdate: (newValue: number) => void
}

export const PriceBoosterSlider: FC<PriceBoosterSliderProps> = ({
  revenueShareInPercentage,
  onPriceBoosterUpdate,
}) => {
  const { t } = useTranslation()
  const [value, setValue] = useState<number[]>([revenueShareInPercentage ?? 20])
  const { mutate } = useUpdateRevShare()
  const { showErrorToast } = useShowErrorToast()

  useEffect(() => {
    if (revenueShareInPercentage) setValue([revenueShareInPercentage])
  }, [revenueShareInPercentage])

  const handleUpdate = useCallback(() => {
    mutate(
      { revenueSharePercentage: value[0] },
      {
        onSuccess: (data) => {
          onPriceBoosterUpdate(data.revenueShareInPercentage)
        },
        onError: () => {
          showErrorToast({
            header: t('priceBooster.updateErrorToast.header'),
            message: t('priceBooster.updateErrorToast.message'),
          })
          setValue([revenueShareInPercentage])
        },
      }
    )
    onPriceBoosterUpdate
  }, [
    mutate,
    onPriceBoosterUpdate,
    revenueShareInPercentage,
    showErrorToast,
    t,
    value,
  ])

  return (
    <div className="w-full flex flex-col mb-12 px-4">
      <div className="flex items-baseline">
        <span className="text-sm mr-1">
          {t('priceBooster.earningsForBoost')}
        </span>
        <span className="text-xl font-bold">{value}%</span>
      </div>
      <div className="w-full flex flex-col my-3">
        <div className="relative flex justify-between text-sm mb-3">
          <span>20%</span>
          <span className="absolute left-[22.5%] sm:left-[23.5%]">40%</span>
          <span className="absolute left-[46%] sm:left-[47.9%]">60%</span>
          <span className="absolute left-[70%] sm:left-[72.2%]">80%</span>
          <span>100%</span>
        </div>
        <Slider
          min={20}
          max={100}
          step={10}
          value={value}
          onValueChange={setValue}
          onPointerUp={handleUpdate}
        />
      </div>
    </div>
  )
}
