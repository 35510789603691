import { useQuery } from '@tanstack/react-query'

import { get } from '@/services/awsAmplify'

import { QueryKeys } from '@/enums'

type SocialMedia = 'twitter' | 'instagram' | 'tiktok' | 'republik'

export type TokenDetailsData = {
  userId: string
  tokenId: string
  username: string
  socials: {
    [key in SocialMedia]: {
      followers: number
      username: string
    }
  }
  price: number
  priceChange24h: number
  priceChange7d: number
  priceChange30d: number
  holders: number
  createdAt: Date
  updatedAt: Date
  revenueShareInPercentage?: number
  accumulativeRevenueShare?: number
  totalBoost: number
}

export const useTokenDetails = (tokenId: string) => {
  const { data, isLoading, error, refetch } = useQuery<TokenDetailsData>({
    queryKey: [QueryKeys.TokenDetails, tokenId],
    queryFn: () => get({ path: `/market/tokens/${tokenId}` }),
    enabled: !!tokenId,
  })

  if (data) {
    const socials = { republik: { username: data?.username, followers: -1 } }

    Object.assign(data, { socials: { ...data?.socials, ...socials } })
  }

  return {
    data,
    isLoading,
    error,
    refetch,
  }
}
