import { useInfiniteQuery } from '@tanstack/react-query'

import { get } from '@/services/awsAmplify'
import { flattenPages } from '@/utils/flattenPages'

import { QueryKeys } from '@/enums'

export type TokenActivity = {
  time: string
  holder: string
  price: number
  side: 'BUY' | 'SELL'
  txHash: string
  amount: number
  value: number
}

interface ActivitiesResponse {
  data: TokenActivity[]
  next?: number
}

export const useTokenActivities = (tokenId: string) => {
  const { data, error, refetch, fetchNextPage, isLoading } = useInfiniteQuery<
    ActivitiesResponse,
    Error
  >({
    queryKey: [QueryKeys.TokenActivities, tokenId],
    queryFn: () => get({ path: `/market/activities/${tokenId}` }),
    initialPageParam: 0,
    getNextPageParam: (lastPage: ActivitiesResponse) =>
      lastPage.next ?? undefined,
    enabled: tokenId != null,
  })

  return {
    data: flattenPages(data, 'items') as TokenActivity[],
    fetchNextPage,
    error,
    refetch,
    isLoading: tokenId == null || isLoading,
  }
}
